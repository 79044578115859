import React, { useLayoutEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import loadable from "react-loadable";
import { E404 } from "components";
import { options } from "hocs/loadable";
import { ToastContainer } from "react-toastify";

const Home = loadable({
  loader: () => import("../views/Home"),
  ...options,
});

const Contact = loadable({
  loader: () => import("../views/Contact"),
  ...options,
});

const Analytics = loadable({
  loader: () => import("../views/Analytics"),
  ...options,
});

const Sanctions = loadable({
  loader: () => import("../views/Sanctions"),
  ...options,
});

const Privacy = loadable({
  loader: () => import("../views/Privacy"),
  ...options,
});

const About = loadable({
  loader: () => import("../views/About"),
  ...options,
});

const FAQ = loadable({
  loader: () => import("../views/FAQ"),
  ...options,
});

const NotFound = () => <E404 message="Page does not exist" isPage={true} />;

export default function SERoutes() {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/analytics" component={Analytics} />
        <Route exact path="/feedback" component={Contact} />
        <Route exact path="/search/:id?" component={Sanctions} />
        <Route exact path="/entity" component={Sanctions} />
        <Route exact path="/privacy" component={Privacy} />
        <Route exact path="/about" component={About} />
        <Route exact path="/faq" component={FAQ} />

        <Route path="*" component={NotFound} />
      </Switch>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    </>
  );
}
