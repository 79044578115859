import React, { Component } from "react";
import { Select2 } from "components/Form";
import countries from "json/countries.json";
import "./Country.style.scss";

export default class Country extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      selected: {},
    };
  }
  componentDidMount() {
    const options = countries.map(({ name }) => {
      return {
        value: name,
        label: name,
      };
    });
    const { value } = this.props;
    let selected = null;
    if (value) {
      selected = { value: value, label: value };
    }
    this.setState({ options, selected });
  }

  handleChange = ({ value }) => {
    this.props.onChange(value);
    this.setState({
      selected: { value: value, label: value },
    });
  };
  render() {
    const { value, onChange, ...restProps } = this.props;
    const { options, selected } = this.state;
    return (
      <Select2
        value={selected}
        options={options}
        onChange={this.handleChange}
        {...restProps}
      />
    );
  }
}
