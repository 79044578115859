import axios from "axios";

export default class APIs {
  static API_URL = process.env.REACT_APP_API_URL;

  /**
   * Make a request to get Analytics data
   * @return {Promise}
   * @param json
   */
  static getAnalyticsData(json) {
    const options = {
      headers: { "Content-Type": "application/json" },
    };
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.API_URL}/analytics`, json, options)
        .then(({ data }) => resolve(data))
        .catch((err) => reject(err));
    });
  }

  /**
   * Make a request to get Map data
   * @return {Promise}
   * @param json
   */
  static getMapData(json) {
    const options = {
      headers: { "Content-Type": "application/json" },
    };
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.API_URL}/analytics-map`, json, options)
        .then(({ data }) => resolve(data))
        .catch((err) => reject(err));
    });
  }

  /**
   * Make a request to get Search data
   * @return {Promise}
   * @param json
   */
  static getSearchData(json) {
    const options = {
      headers: { "Content-Type": "application/json" },
    };
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.API_URL}/search`, json, options)
        .then(({ data }) => resolve(data))
        .catch((err) => reject(err));
    });
  }

  /**
   * Make a request to get Entity data
   * @return {Promise}
   * @param entity_id
   */
  static getEntity(entity_id) {
    const options = {
      headers: { "Content-Type": "application/json" },
    };
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.API_URL}/entity?id=${entity_id}`, options)
        .then(({ data }) => resolve(data))
        .catch((err) => reject(err));
    });
  }

  /**
   * Make a request to get Home Info
   * @return {Promise}
   * @param entity_id
   */
  static getInfo() {
    const options = {
      headers: { "Content-Type": "application/json" },
    };
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.API_URL}/info`, options)
        .then(({ data }) => resolve(data))
        .catch((err) => reject(err));
    });
  }

  /**
   * Make a request to get Map data
   * @return {Promise}
   * @param json
   */
  static sendFeedback(json) {
    const options = {
      headers: { "Content-Type": "application/json" },
    };
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.API_URL}/feedback`, json, options)
        .then(({ data }) => resolve(data))
        .catch((err) => reject(err));
    });
  }

  /**
   * Make a request to subscribe
   * @return {Promise}
   * @param json
   */
  static addSubscribe(json) {
    const options = {
      headers: { "Content-Type": "application/json" },
    };
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.API_URL}/subscribe`, json, options)
        .then(({ data }) => resolve(data))
        .catch((err) => reject(err));
    });
  }

  /**
   * Make a request to Initiate Export
   * @return {Promise}
   * @param json
   */
  static initiateExport(json) {
    const options = {
      headers: { "Content-Type": "application/json" },
    };
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.API_URL}/export`, json, options)
        .then(({ data }) => resolve(data))
        .catch((err) => reject(err));
    });
  }

  /**
   * Make a request to Check Export Status
   * @return {Promise}
   * @param json
   */
  static checkExportStatus(job_id) {
    const options = {
      headers: { "Content-Type": "application/json" },
    };
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.API_URL}/export?job_id=${job_id}`, options)
        .then(({ data }) => resolve(data))
        .catch((err) => reject(err));
    });
  }
}
