import React, { Component } from "react";
import classnames from "classnames";
import { Button } from "reactstrap";
import "./Button.style.scss";

export default class SEButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      children,
      className,
      disabled,
      isLoading = false,
      loadingText = "Submitting..",
      ...restProps
    } = this.props;

    return (
      <Button
        disabled={disabled || isLoading}
        className={classnames("se-button", className)}
        {...restProps}
      >
        {isLoading ? loadingText : children}
      </Button>
    );
  }
}
