import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Button, Modal, FormGroup } from "reactstrap";
import "./GoToPage.style.scss";

class GoToPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      goto: "",
    };
  }

  toggle = (e) => {
    e.preventDefault();
    this.props.onClose();
  };

  handleSubmitGoto = (e) => {
    const { goto } = this.state;
    this.props.onSubmit(e, goto);
    this.setState({
      goto: "",
    });
  };

  render() {
    const { show } = this.props;

    return (
      <div>
        <Modal
          toggle={this.toggle}
          size="md"
          isOpen={show || false}
          className="modal-class"
        >
          <div className="inner-record-goto text-center">
            <h5>Go to Page</h5>
            <div className="d-flex align-items-center justify-content-center mt-3">
              <h6>Page number</h6>
              <FormGroup>
                <input
                  className="form-control"
                  value={this.state.goto}
                  onChange={(e) => this.setState({ goto: e.target.value })}
                />
              </FormGroup>
            </div>
            <div className="mt-4">
              <Button onClick={this.toggle} outline={true} color="primary">
                Cancel
              </Button>
              <Button onClick={this.handleSubmitGoto} color="primary">
                Go
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default withRouter(GoToPage);
