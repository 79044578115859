import React, { useState } from "react";
import { Tooltip } from "reactstrap";
import "./Tooltip.style.scss";

const SETooltip = (props) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const { label, tooltip } = props;
  return (
    <>
      <span className="se-tooltip-label" id={"Tooltip-" + props.id}>
        {label}
      </span>
      <Tooltip
        //placement="top"
        isOpen={tooltipOpen}
        target={"Tooltip-" + props.id}
        toggle={toggle}
      >
        {tooltip}
      </Tooltip>
    </>
  );
};

export default SETooltip;
