import React from "react";
import PropTypes from "prop-types";
import { Select2 } from "components/Form";
import DatePickerRange from "../DatePickerRange";
import "./EventByDate.style.scss";

class EventByDate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      event: null,
      startDate: null,
      endDate: null,
    };
  }

  generateEventOptions = () => {
    return [
      {
        label: "Added",
        value: "Added",
      },
      {
        label: "Modified",
        value: "Modified",
      },
      {
        label: "Removed",
        value: "Removed",
      },
    ];
  };

  handleChange = (field, value) => {
    const state = { ...this.state };
    this.setState({
      [field]: value,
    });
    state[field] = value;
    const result = {
      event: state.event ? state.event.value : state.event,
      startDate: state.startDate,
      endDate: state.endDate,
    };
    this.props.onChange(result);
  };

  handleDatePickerChange = ({ startDate, endDate }) => {
    this.setState({ startDate, endDate });
    this.props.onChange({ event: this.state.event, startDate, endDate });
  };

  render() {
    const eventOptions = this.generateEventOptions();
    const { event = null, startDate, endDate } = this.state;

    return (
      <>
        <DatePickerRange
          className="filter-group mt-1"
          value={{ startDate, endDate }}
          onChange={this.handleDatePickerChange}
        />
        <Select2
          className="mt-2"
          onChange={(val) => this.handleChange("event", val)}
          value={event}
          placeholder="Choose Event"
          options={eventOptions}
        />
      </>
    );
  }
}

EventByDate.propTypes = {
  onChange: PropTypes.func,
};

EventByDate.defaultProps = {
  onChange: () => {},
};

export default EventByDate;
