import React, { Component } from "react";
import { Modal, Table } from "reactstrap";
import "./MapDetail.style.scss";

class MapDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  toggle = (e) => {
    e.preventDefault();
    this.props.onClose();
  };

  render() {
    const abbr = {
      e: "EU_entities_count",
      ep: "EU Programs",
      o: "OFAC_entities_count",
      op: "OFAC Programs",
      k: "UK_entities_count",
      kp: "UK Programs",
      u: "UN_entities_count",
      up: "UN Programs",
      b: "BIS_entities_count",
      bp: "BIS Programs",
    };
    const { show, data: mapData = {} } = this.props;
    const { label, data = {}, color } = mapData;
    const { meta } = data;

    console.log("meta", meta);

    let ep = "NA",
      e = 0,
      op = "NA",
      o = 0,
      up = "NA",
      u = 0,
      kp = "NA",
      k = 0,
      bp = "NA",
      b = 0;
    if (meta) {
      ep = meta["ep"] ? meta["ep"].join(", ") : "NA";
      e = meta["e"] ? meta["e"] : "NA";

      op = meta["op"] ? meta["op"].join(", ") : "NA";
      o = meta["o"] ? meta["o"] : "NA";

      up = meta["up"] ? meta["up"].join(", ") : "NA";
      u = meta["u"] ? meta["u"] : "NA";

      kp = meta["kp"] ? meta["kp"].join(", ") : "NA";
      k = meta["k"] ? meta["k"] : "NA";

      bp = meta["bp"] ? meta["bp"].join(", ") : "NA";
      b = meta["b"] ? meta["b"] : "NA";
    }

    return (
      <Modal
        toggle={this.toggle}
        size="lg"
        isOpen={show || false}
        className="map-details"
      >
        <h4>
          {label} <span style={{ background: color }}>&nbsp;</span>
        </h4>
        <Table>
          <thead>
            <tr>
              <th>Authority</th>
              <th>Programs</th>
              <th>Entity Count</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{abbr["ep"]}</td>
              <td>{ep}</td>
              <td>{e}</td>
            </tr>
            <tr>
              <td>{abbr["op"]}</td>
              <td>{op}</td>
              <td>{o}</td>
            </tr>
            <tr>
              <td>{abbr["up"]}</td>
              <td>{up}</td>
              <td>{u}</td>
            </tr>
            <tr>
              <td>{abbr["kp"]}</td>
              <td>{kp}</td>
              <td>{k}</td>
            </tr>
            <tr>
              <td>{abbr["bp"]}</td>
              <td>{bp}</td>
              <td>{b}</td>
            </tr>
          </tbody>
        </Table>
      </Modal>
    );
  }
}

export default MapDetail;
