import React from "react";
import { Offline, Online } from "react-detect-offline";
import "./InternetCut.style.scss";
class InternetCut extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  handleConnectivity = (status) => {
    if (!status) {
      //No internet
      document.body.classList.add("no-internet");
    } else {
      document.body.className = document.body.className.replace(
        "no-internet",
        ""
      );
    }
    this.setState({
      show: true,
    });
  };

  render() {
    const { show } = this.state;
    return (
      <div className="internet-connectivity">
        {show && (
          <Online onChange={this.handleConnectivity}>
            <div role="alert" className="text-center alert-success p-2">
              Internet connected.
            </div>
          </Online>
        )}
        <Offline onChange={this.handleConnectivity}>
          <div role="alert" className="text-center alert-danger p-2">
            Internet connection is lost.
          </div>
        </Offline>
      </div>
    );
  }
}

export default InternetCut;
