import React from "react";
import { Container, Row, Col } from "reactstrap";
import classnames from "classnames";
import { Link } from "react-router-dom";
import "./Footer.style.scss";
export default class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { className, fluid = false } = this.props;
    return (
      <footer className={classnames("footer-outer", className)}>
        <Container fluid={fluid}>
          <Row className="footer-flex-position">
            <Col sm={12} md={12} lg={6}>
              <div className="logo-and-details">
                <div className="logo-c4ads">
                  <img src="/images/c4ads-black.png" alt="" />
                </div>
                C4ADS is a nonprofit organization dedicated to providing
                data-driven analysis and evidence-based reporting on global
                conflict and transnational security issues.
              </div>
            </Col>
            <Col sm={4} md={4} lg={2}>
              <ul className="footer-links text-lg-right text-md-left text-sm-left mt-sm-3 mt-xs-3">
                <li>
                  <Link to="/feedback">Contact Us</Link>
                </li>
                <li className="mb-0">
                  <a href="mailto:info@c4ads.org">info@c4ads.org</a>
                </li>
              </ul>
            </Col>
            <Col sm={4} md={4} lg={2}>
              <ul className="footer-links text-lg-right text-md-center text-sm-center mt-sm-3">
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li className="mb-0">
                  <Link to="/faq">FAQ</Link>
                </li>
              </ul>
            </Col>
            <Col sm={4} md={4} lg={2}>
              <ul className="footer-links text-lg-right text-md-right mt-sm-3">
                <li>
                  <Link to="/privacy">Privacy Policy</Link>
                </li>
                <li className="mb-0 empty">
                  <a title="blank" href="#">
                    &nbsp;
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </footer>
    );
  }
}
