import React from "react";
import { Link, withRouter } from "react-router-dom";
import classnames from "classnames";
import {
  Collapse,
  Container,
  Nav,
  Navbar,
  NavbarToggler,
  NavItem,
} from "reactstrap";

import "./Header.style.scss";

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    const { history } = this.props;

    return (
      <Navbar
        fixed="top"
        className="header-desktop rd-shadow"
        light
        expand="md"
      >
        <Container className="pt-2 pb-2">
          <Link to="/" className="navbar-brand">
            <img src="/images/logo.png" alt="Sanction Explorer Logo" />
          </Link>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="w-100" navbar>
              <NavItem
                className={classnames({
                  "ml-md-auto": true,
                  active: history.location.pathname === "/search",
                })}
              >
                <Link className="nav-link" to="/search">
                  Search
                </Link>
              </NavItem>
              <NavItem
                className={classnames({
                  active: history.location.pathname === "/analytics",
                })}
              >
                <Link className="nav-link" to="/analytics">
                  Analytics
                </Link>
              </NavItem>
              <NavItem
                className={classnames({
                  active: history.location.pathname === "/feedback",
                })}
              >
                <Link className="nav-link" to="/feedback">
                  Feedback
                </Link>
              </NavItem>
              <NavItem
                className={classnames({
                  active: history.location.pathname === "/about",
                })}
              >
                <Link className="nav-link" to="/about">
                  About
                </Link>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default withRouter(Header);
