import React from "react";
import { Pagination, PaginationItem, PaginationLink, Button } from "reactstrap";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { GoToPage } from "components/Modals";
import "./pagination.style.scss";

class SEPagination extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 1,
      current_page: 1,
      disablePrev: true,
      disableNext: false,
      showGotoPage: false,
    };
  }

  goToPrev = (e) => {
    e.preventDefault();
    let newPage = --this.state.current_page;
    if (newPage > 0) {
      //console.log('prev',newPage)
      this.props.history.push(`#${newPage}`);
      this.props.onPageChange(newPage);
      this.setState({
        current_page: newPage,
      });
    } else {
      this.setState({
        current_page: 1,
      });
    }
    this.handleArrow(newPage);
  };

  handleArrow = (val) => {
    let disablePrev, disableNext;

    if (val >= this.state.total) {
      disablePrev = true;
      disableNext = false;
    } else if (val <= 1) {
      disablePrev = false;
      disableNext = true;
    } else {
      disablePrev = true;
      disableNext = true;
    }

    //console.log(disablePrev,disableNext)

    this.setState({
      disablePrev,
      disableNext,
    });
  };

  goToNext = (e, page = null) => {
    //e.preventDefault();
    let newPage = ++this.state.current_page;
    if (page) {
      newPage = page;
    }
    if (newPage <= this.state.total) {
      //console.log('next',newPage)
      this.props.history.push(`#${newPage}`);
      this.props.onPageChange(newPage);
      this.setState({
        current_page: newPage,
        showGotoPage: false,
      });
    } else {
      this.setState({
        current_page: this.state.total,
        showGotoPage: false,
      });
    }

    this.handleArrow(newPage);
  };

  changeToCurrentPage = (e, page) => {
    if (!this.isInt(page)) return;

    this.props.onPageChange(page);
    this.setState({
      current_page: page,
    });
  };

  componentWillReceiveProps(props) {
    const { data } = props;
    const { last_page = 1, page = 1, total } = data;

    this.setState({
      current_page: page,
      total: last_page,
      totalRecords: total,
    });
  }

  componentWillMount() {
    const { data } = this.props;
    const { last_page = 1, page = 1, total } = data;

    this.setState({
      current_page: page,
      total: last_page,
      totalRecords: total,
    });
  }

  renderGoToPrompt = () => {
    return (
      <GoToPage
        show={this.state.showGotoPage}
        onClose={() => this.setState({ showGotoPage: false })}
        onSubmit={(e, pageNumber) => this.goToNext(e, pageNumber)}
      />
    );
  };

  isInt = (val) => {
    return typeof val === "number" && val % 1 === 0;
  };

  getPageNumbers = (lastPage, currentPage) => {
    let pageNumbers = [];
    if (lastPage <= 8) {
      pageNumbers = [...Array(lastPage).keys()].map((n) => n + 1);
    } else {
      if (currentPage <= 6) {
        pageNumbers = [...Array(7).keys()].map((n) => n + 1);
        pageNumbers.push("...");
        pageNumbers.push(lastPage);
      } else if (currentPage >= lastPage - 6) {
        pageNumbers.push(1);
        pageNumbers.push("...");
        pageNumbers.push(lastPage - 6);
        pageNumbers.push(lastPage - 5);
        pageNumbers.push(lastPage - 4);
        pageNumbers.push(lastPage - 3);
        pageNumbers.push(lastPage - 2);
        pageNumbers.push(lastPage - 1);
        pageNumbers.push(lastPage);
      } else {
        if (currentPage === 1) return;
        pageNumbers.push(1);
        pageNumbers.push("...");
        pageNumbers.push(currentPage - 2);
        pageNumbers.push(currentPage - 1);
        pageNumbers.push(currentPage);
        pageNumbers.push(currentPage + 1);
        pageNumbers.push(currentPage + 2);
        pageNumbers.push("...");
        pageNumbers.push(lastPage);
      }
    }

    return pageNumbers;
  };

  render() {
    if (this.state.total <= 1) return "";
    let { last_page } = this.props.data;

    const pageNumbers = this.getPageNumbers(last_page, this.state.current_page);
    let pages = pageNumbers.map((item, index) => {
      const href = this.isInt(item) ? item : "";
      return (
        <PaginationItem active={this.state.current_page === item} key={index}>
          <PaginationLink
            onClick={(e) => this.changeToCurrentPage(e, item)}
            next={this.state.disableNext}
            previous={this.state.disablePrev}
            href={"#" + href}
          >
            {item}
          </PaginationLink>
        </PaginationItem>
      );
    });

    return (
      <div className="se-pagination">
        <Pagination aria-label="Page navigation">
          <PaginationItem>
            <PaginationLink onClick={this.goToPrev} previous href="#" />
          </PaginationItem>
          {pages}
          <PaginationItem>
            <PaginationLink onClick={this.goToNext} next href="#" />
          </PaginationItem>

          <Button
            className="goto-btn"
            onClick={() => this.setState({ showGotoPage: true })}
            outline={true}
            color="primary"
          >
            Go to page ..
          </Button>
        </Pagination>
        {this.renderGoToPrompt()}
      </div>
    );
  }
}

SEPagination.propTypes = {
  data: PropTypes.object.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

SEPagination.defaultProps = {
  onPageChange: () => null,
};

export default withRouter(SEPagination);
