const initialState = {};

export default function programs(state = initialState, action) {
  switch (action.type) {
    case "SET_PROGRAMS":
      return {
        ...action.data,
      };

    default:
      return state;
  }
}
