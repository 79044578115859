import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import misc from "./reducers/misc";
const reducer = combineReducers({ misc });

let composeEnhancers = compose;
if (process.env.NODE_ENV !== "production") {
  if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      serialize: true,
    });
  }
}

export default createStore(reducer, composeEnhancers(applyMiddleware(thunk)));
