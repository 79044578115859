import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import "./E404.style.scss";
class E404 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { message, isPage } = this.props;

    return (
      <div
        className={classNames({
          "item-404": !isPage,
          "item-404-page": isPage,
        })}
      >
        <div>{message}</div>
      </div>
    );
  }
}

E404.propTypes = {
  message: PropTypes.string.isRequired,
  isPage: PropTypes.bool,
};

E404.defaultProps = {
  message: "Requested Item does not exist",
  isPage: false,
};

export default E404;
