import React, { Component } from "react";
import { Modal, ModalHeader, Row, Col, Button } from "reactstrap";
import { Feedback } from "components";
import "./Feedback.style.scss";

class FeedbackModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  toggle = (e) => {
    e.preventDefault();
    this.props.onClose();
  };

  render() {
    const { show } = this.props;

    return (
      <Modal
        toggle={this.toggle}
        size="lg"
        isOpen={show || false}
        className="feedback-modal"
      >
        <ModalHeader toggle={this.toggle}>Feedback</ModalHeader>
        If your searches are consistently failing when they shouldn't be, or you
        encounter any other issues,{" "}
        <span className="underline">please let us know</span>.
        <br />
        <Feedback defaultType="Bug Report" onClose={this.props.onClose} />
      </Modal>
    );
  }
}

export default FeedbackModal;
