import React, { Component } from "react";
import { Button, Modal } from "reactstrap";
import "./LeavePrograms.style.scss";

class LeavePrograms extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  toggle = (e) => {
    e.preventDefault();
    this.props.onClose();
  };

  handleContinue = (e) => {
    e.preventDefault();
    this.props.onContinue();
  };

  render() {
    const { show } = this.props;

    return (
      <Modal
        toggle={this.toggle}
        size="md"
        isOpen={show || false}
        className="leave-programs-modal"
      >
        <h4>Are you sure you want to close without selecting programs?</h4>
        <div className="d-flex buttons">
          <a onClick={(e) => this.handleContinue(e)} href="#">
            No, continue selecting
          </a>
          <Button className="ml-auto" onClick={this.toggle} color="primary">
            Close
          </Button>
        </div>
      </Modal>
    );
  }
}

export default LeavePrograms;
