export const OFACDescription = {
  BALKANS:
    'Western Balkans Stabilization Regulations, <a target="_blank" href="http://www.ecfr.gov/cgi-bin/text-idx?SID=5eae22affe2e3675b6deaa3fb796eb7a&tpl=/ecfrbrowse/Title31/31cfr588_main_02.tpl">31 C.F.R. part 588</a>; <a target="_blank" href="https://home.treasury.gov/system/files/126/13304.pdf">Executive Order 13304</a>',
  BELARUS:
    'Belarus Sanctions Regulations, <a target="_blank" href="http://www.ecfr.gov/cgi-bin/text-idx?SID=5eae22affe2e3675b6deaa3fb796eb7a&tpl=/ecfrbrowse/Title31/31cfr548_main_02.tpl">31 C.F.R. part 548</a>; <a target="_blank" href="https://home.treasury.gov/system/files/126/13405.pdf">Executive Order 13405</a>',
  "BPI-PA":
    'Blocked Pending Investigation, <a target="_blank" href="https://home.treasury.gov/system/files/126/hr3162.pdf">Patriot Act</a>',
  "BPI-SDNTK":
    'Blocked Pending Investigation, Foreign Narcotics Kingpin Sanctions Regulations, <a target="_blank" href="http://www.ecfr.gov/cgi-bin/text-idx?SID=5eae22affe2e3675b6deaa3fb796eb7a&tpl=/ecfrbrowse/Title31/31cfr598_main_02.tpl">31 C.F.R. part 598</a>',
  BURUNDI:
    '<a href="https://home.treasury.gov/system/files/126/13712.pdf" target="_blank">Executive Order 13712</a>',
  "CAATSA-RUSSIA":
    '<a href="https://home.treasury.gov/system/files/126/hr3364_pl115-44.pdf">​Countering America\'s Adversaries Through Sanctions Act of 2017 (CAATSA) - Title II</a>',
  CAR:
    '​Central African Republic Sanctions, <a href="http://www.ecfr.gov/cgi-bin/text-idx?SID=0107483c0b76405828ee0a5fcb047046&tpl=/ecfrbrowse/Title31/31cfr553_main_02.tpl" target="_blank">31 C.F.R. part 553</a>',
  COTED:
    'Cote d\'Ivoire Sanctions Regulations, <a target="_blank" href="http://www.ecfr.gov/cgi-bin/text-idx?SID=5eae22affe2e3675b6deaa3fb796eb7a&tpl=/ecfrbrowse/Title31/31cfr543_main_02.tpl">31 C.F.R. part 543</a>',
  CUBA:
    'Cuban Assets Control Regulations, <a target="_blank" href="http://www.ecfr.gov/cgi-bin/text-idx?SID=6a1b854e353e6dc66b86b575bb20f260&mc=true&node=pt31.3.515&rgn=div5">31 C.F.R. part 515</a>',
  CYBER2:
    '<a href="https://home.treasury.gov/system/files/126/cyber_eo.pdf" target="_blank">Executive Order 13694</a>;<a href="https://home.treasury.gov/system/files/126/cyber2_eo.pdf" target="_blank">​Executive Order 13757</a>',
  DARFUR:
    'Darfur Sanctions Regulations, <a target="_blank" href="http://www.ecfr.gov/cgi-bin/text-idx?SID=5eae22affe2e3675b6deaa3fb796eb7a&tpl=/ecfrbrowse/Title31/31cfr546_main_02.tpl">31 C.F.R. part 546</a>',
  DPRK:
    'North Korea Sanctions Regulations, <a target="_blank" href="http://www.ecfr.gov/cgi-bin/text-idx?c=ecfr&SID=536d46786b2cf91e15af320ee7f1008a&tpl=/ecfrbrowse/Title31/31cfr510_main_02.tpl">31 C.F.R. part 510</a>;<a target="_blank" href="https://home.treasury.gov/system/files/126/13551.pdf">Executive Order 13551</a>',
  DPRK2:
    '<a href="https://home.treasury.gov/system/files/126/13687.pdf" target="_blank">​Executive Order 13687</a>',
  DPRK3:
    '<a href="https://home.treasury.gov/system/files/126/nk_eo_20160316.pdf" target="_blank">Executive Order 13722</a>',
  DPRK4:
    '<a href="https://home.treasury.gov/system/files/126/13810.pdf" target="_blank">Executive Order 13810</a>',
  DRCONGO:
    'Democratic Republic of the Congo Sanctions Regulations, <a href="http://www.ecfr.gov/cgi-bin/text-idx?SID=5eae22affe2e3675b6deaa3fb796eb7a&tpl=/ecfrbrowse/Title31/31cfr547_main_02.tpl" target="_blank">31 C.F.R. 547​</a>',
  "ELECTION-EO13848":
    '<a href="https://home.treasury.gov/system/files/126/election_executive_order_13848.pdf" target="_blank">Executive Order 13848</a>',
  "FSE-SY":
    'Foreign Sanctions Evaders <a href="https://home.treasury.gov/system/files/126/fse_eo.pdf" target="_blank">Executive Order 13608</a> - Syria​',
  "FSE-IR":
    'Foreign Sanctions Evaders <a href="https://home.treasury.gov/system/files/126/fse_eo.pdf" target="_blank">Executive Order 13608</a> - Iran​',
  FTO:
    'Foreign Terrorist Organizations Sanctions Regulations, <a href="http://www.ecfr.gov/cgi-bin/text-idx?SID=5eae22affe2e3675b6deaa3fb796eb7a&tpl=/ecfrbrowse/Title31/31cfr597_main_02.tpl" target="_blank">31 C.F.R. part 597</a>',
  GLOMAG:
    '<a href="https://home.treasury.gov/system/files/126/glomag_eo.pdf" target="_blank">Executive Order 13818</a> -  (Global Magnitsky)​',
  HIFPAA:
    '<a href="https://www.treasury.gov/resource-center/sanctions/Programs/Documents/hifpaa_2018.pdf" target="_blank">Hizballah International Financing Prevention Amendments Act of 2018, Public Law No: 115-272</a>',
  "HRIT-SY":
    '<a href="https://home.treasury.gov/system/files/126/13606.pdf" target="_blank">Executive Order 13606</a> - Syria​',
  "HRIT-IR":
    'href="https://home.treasury.gov/system/files/126/13606.pdf" target="_blank">Executive Order 13606</a> - Iran​',
  IFCA:
    '<a href="https://home.treasury.gov/system/files/126/pl112_239.pdf" target="_blank">Iran Freedom and Counter-Proliferation Act of 2012 (IFCA) (PL 112-239)​</a>',
  IFSR:
    'Iranian Financial Sanctions Regulations, <a href="http://www.ecfr.gov/cgi-bin/text-idx?SID=9874a1de71122f946a56d27417c6c372&tpl=/ecfrbrowse/Title31/31cfr561_main_02.tpl" target="_blank">31 CFR part 561</a>',
  IRAN:
    'Iranian Transactions and Sanctions Regulations, <a href="http://www.ecfr.gov/cgi-bin/text-idx?SID=0107483c0b76405828ee0a5fcb047046&tpl=/ecfrbrowse/Title31/31cfr560_main_02.tpl" target="_blank">31 CFR part 560</a>​',
  "IRAN-EO13846":
    '<a href="https://home.treasury.gov/system/files/126/13846.pdf" target="_blank">Executive Order 13846</a>',
  "IRAN-EO13876":
    '<a target="_blank" href="https://home.treasury.gov/system/files/126/13876.pdf">Executive Order 13876</a>',
  "IRAN-HR":
    '<a href="https://home.treasury.gov/system/files/126/13553.pdf" target="_blank">Executive Order 13553</a>',
  "IRAN-TRA":
    '<a href="https://home.treasury.gov/system/files/126/2012iranthreat_eo.pdf" target="_blank">Iran&nbsp;Threat Reduction and Syria Human Rights Act of 2012​</a>',
  IRAQ2:
    '<a href="https://home.treasury.gov/system/files/126/13315.pdf" target="_blank">Executive Order 13315</a>; <a href="https://home.treasury.gov/system/files/126/13350.pdf" target="_blank">Executive Order 13350</a>',
  IRAQ3:
    '<a href="https://home.treasury.gov/system/files/126/13438.pdf" target="_blank">Executive Order 13438</a>',
  IRGC:
    'Iranian Financial Sanctions Regulations, <a href="http://www.ecfr.gov/cgi-bin/text-idx?SID=9874a1de71122f946a56d27417c6c372&amp;tpl=/ecfrbrowse/Title31/31cfr561_main_02.tpl" target="_blank">31 CFR Part 561​</a>​',
  ISA:
    'Iran Sanctions Act, <a href="https://home.treasury.gov/system/files/126/2011_isa_eo.pdf" target="_blank">Executive Order 13574​</a>​',
  LEBANON:
    'Lebanon Sanctions Regulations, <a href="http://www.ecfr.gov/cgi-bin/text-idx?SID=5eae22affe2e3675b6deaa3fb796eb7a&amp;tpl=/ecfrbrowse/Title31/31cfr549_main_02.tpl" target="_blank">31 C.F.R. part 549</a>; <a href="https://home.treasury.gov/system/files/126/13441.pdf" target="_blank">Executive Order 13441</a>',
  LIBERIA:
    'Former Liberian Regime of Charles Taylor Sanctions Regulations, <a href="http://www.ecfr.gov/cgi-bin/text-idx?SID=9874a1de71122f946a56d27417c6c372&amp;tpl=/ecfrbrowse/Title31/31cfr593_main_02.tpl" target="_blank">31 C.F.R. part 593​</a>',
  LIBYA2:
    'Libyan Sanctions, <a href="http://www.ecfr.gov/cgi-bin/text-idx?SID=5eae22affe2e3675b6deaa3fb796eb7a&amp;tpl=/ecfrbrowse/Title31/31cfr570_main_02.tpl" target="_blank">31 C.F.R. part 570</a>',
  LIBYA3:
    '<a href="https://home.treasury.gov/system/files/126/libya_eo_20160419.pdf" target="_blank">Executive Order 13726</a>',
  MAGNIT:
    '<a href="https://home.treasury.gov/system/files/126/pl112_208.pdf" target="_blank">Sergei Magnitsky Rule of Law Accountability Act of 2012​</a>',
  NICARAGUA:
    '<a href="https://home.treasury.gov/system/files/126/nicaragua_eo.pdf" target="_blank">​Executive Order 13851</a>',
  "MALI-EO13882":
    '<a href="https://home.treasury.gov/system/files/126/13882.pdf">Executive Order 13882</a>',
  "NICARAGUA-NHRAA":
    '<a href="https://home.treasury.gov/system/files/126/nica_2018.pdf">Nicaragua Human Rights and Anticorruption Act of 2018</a>',
  NPWMD:
    'Weapons of Mass Destruction Proliferators Sanctions Regulations, <a href="http://www.ecfr.gov/cgi-bin/text-idx?c=ecfr&amp;SID=9c56be4b62298b390b0ff99de1f08f69&amp;tpl=/ecfrbrowse/Title31/31cfr544_main_02.tpl" target="_blank">31 C.F.R. part 544​</a>​',
  "NS-PLC":
    '​Non-SDN, Palestinian Legislative Council List, <a href="https://home.treasury.gov/system/files/126/plc_gl4.pdf" target="_blank">General License 4​</a> issued pursuant to the Global Terrorism Sanctions Regulations (<a href="http://www.ecfr.gov/cgi-bin/text-idx?SID=5eae22affe2e3675b6deaa3fb796eb7a&amp;tpl=/ecfrbrowse/Title31/31cfr594_main_02.tpl" target="_blank">31 C.F.R. Part 594</a>)',
  SDGT:
    'Global Terrorism Sanctions Regulations, <a href="http://www.ecfr.gov/cgi-bin/text-idx?SID=5eae22affe2e3675b6deaa3fb796eb7a&amp;tpl=/ecfrbrowse/Title31/31cfr594_main_02.tpl" target="_blank">31 C.F.R. part 594​</a>',
  SDNT:
    'Narcotics Trafficking Sanctions Regulations, <a href="http://www.ecfr.gov/cgi-bin/text-idx?SID=5eae22affe2e3675b6deaa3fb796eb7a&amp;tpl=/ecfrbrowse/Title31/31cfr536_main_02.tpl" target="_blank">31 C.F.R. part 536​</a>',
  SDNTK:
    'Foreign Narcotics Kingpin Sanctions Regulations, <a href="http://www.ecfr.gov/cgi-bin/text-idx?SID=5eae22affe2e3675b6deaa3fb796eb7a&amp;tpl=/ecfrbrowse/Title31/31cfr598_main_02.tpl" target="_blank">31 C.F.R. part 598</a>',
  SDT:
    'Terrorism Sanctions Regulations, <a href="http://www.ecfr.gov/cgi-bin/text-idx?SID=5eae22affe2e3675b6deaa3fb796eb7a&amp;tpl=/ecfrbrowse/Title31/31cfr595_main_02.tpl" target="_blank">31 C.F.R. part 595​</a>​',
  SOMALIA:
    'Somalia Sanctions Regulations, <a href="http://www.ecfr.gov/cgi-bin/text-idx?SID=5eae22affe2e3675b6deaa3fb796eb7a&amp;tpl=/ecfrbrowse/Title31/31cfr551_main_02.tpl" target="_blank">31 C.F.R. part 551</a>',
  SUDAN:
    'Sudanese Sanctions Regulations, <a href="http://www.ecfr.gov/cgi-bin/text-idx?SID=5eae22affe2e3675b6deaa3fb796eb7a&amp;tpl=/ecfrbrowse/Title31/31cfr538_main_02.tpl" target="_blank">31 C.F.R. part 538​</a>',
  SOUTHSUDAN:
    '​South Sudan Sanctions Regulations, <a href="http://www.ecfr.gov/cgi-bin/text-idx?SID=b918fda47d9009c40b21fac364bc1b86&amp;tpl=/ecfrbrowse/Title31/31cfr558_main_02.tpl" target="_blank">31 C.F.R. part 558</a>;<a href="https://home.treasury.gov/system/files/126/south_sudan_eo.pdf" target="_blank">Executive Order 13664</a>',
  SYRIA:
    '<a href="https://home.treasury.gov/system/files/126/13338.pdf">Executive Order 13338</a>; <a href="https://home.treasury.gov/system/files/126/13399.pdf">Executive Order 13399</a>; <a href="https://home.treasury.gov/system/files/126/13460.pdf">Executive Order 13460</a>; <a href="https://home.treasury.gov/system/files/126/13572.pdf">Executive Order 13572</a>; <a href="https://home.treasury.gov/system/files/126/13573.pdf">Executive Order 13573</a>; <a href="https://home.treasury.gov/system/files/126/syria_eo_08182011.pdf">Executive Order 13582</a>',
  "SYRIA-EO13894":
    '<a href="https://home.treasury.gov/system/files/126/13894.pdf">Executive Order on the Situation in Syria of October 14, 2019</a>',
  TCO:
    'Transnational Criminal Organizations Sanctions Regulations, <a href="http://www.ecfr.gov/cgi-bin/text-idx?SID=5eae22affe2e3675b6deaa3fb796eb7a&amp;tpl=/ecfrbrowse/Title31/31cfr590_main_02.tpl" target="_blank">31 C.F.R. part 590</a>; <a href="https://home.treasury.gov/system/files/126/13581.pdf" target="_blank">Executive Order 13581</a>',
  "UKRAINE-EO13660":
    '<a href="https://home.treasury.gov/system/files/126/ukraine_eo.pdf" target="_blank">Executive Order 13660​</a>',
  "UKRAINE-EO13661":
    '<a href="https://home.treasury.gov/system/files/126/ukraine_eo2.pdf" target="_blank">Executive Order 13661​</a>',
  "UKRAINE-EO13662":
    '​<a href="https://home.treasury.gov/system/files/126/ukraine_eo3.pdf" target="_blank">Executive Order 13662</a> (See <a href="https://home.treasury.gov/system/files/126/eo13662_directive1.pdf" target="_blank">Directive 1 As Amended</a>, <a href="https://home.treasury.gov/system/files/126/eo13662_directive2.pdf" target="_blank">Directive 2 as Amended</a>, <a href="https://home.treasury.gov/system/files/126/eo13662_directive3.pdf" target="_blank">Directive 3</a>, <a href="https://home.treasury.gov/system/files/126/eo13662_directive4.pdf" target="_blank">Directive 4</a>)',
  VENEZUELA:
    '<a href="https://home.treasury.gov/system/files/126/13692.pdf" target="_blank">Executive Order 13692​</a>',
  "VENEZUELA-EO13850":
    '<a href="https://home.treasury.gov/system/files/126/venezuela_eo_13850.pdf" target="_blank">​Executive Order 13850</a>',
  "VENEZUELA-EO13884":
    '<a href="https://home.treasury.gov/system/files/126/13884_0.pdf">Executive Order 13884</a>',
  YEMEN:
    '	​Yemen Sanctions Regulations, <a href="http://www.ecfr.gov/cgi-bin/text-idx?SID=5eae22affe2e3675b6deaa3fb796eb7a&amp;tpl=/ecfrbrowse/Title31/31cfr552_main_02.tpl" target="_blank">31 C.F.R. part 552</a>',
  ZIMBABWE:
    'Zimbabwe Sanctions Regulations, <a href="http://www.ecfr.gov/cgi-bin/text-idx?SID=5eae22affe2e3675b6deaa3fb796eb7a&amp;tpl=/ecfrbrowse/Title31/31cfr541_main_02.tpl" target="_blank">31 C.F.R. part 541</a>; <a href="https://home.treasury.gov/system/files/126/13391.pdf" target="_blank">Executive Order 13391</a>; <a href="https://home.treasury.gov/system/files/126/13469.pdf" target="_blank">Executive Order 13469</a>',
};

export const UNDescription = {
  "AL-QAIDA":
    '<a href="https://www.undocs.org/S/RES/1267(1999)" target="_blank">Resolution 1267</a>, <a href="https://www.undocs.org/S/RES/1988%20(2011)" target="_blank">Resolution 1989</a>, <a href="https://www.undocs.org/S/RES/2253(2015)" target="_blank">Resolution 2253</a>',
  CAR:
    'Central African Republic, <a href="https://www.undocs.org/S/RES/2127(2013)" target="_blank">Resolution 2127</a>, <a href="https://www.undocs.org/S/RES/2536(2020)" target="_blank">Resolution 2536</a>',
  DPRK:
    '<a href="https://www.undocs.org/S/RES/1718%20(2006)" target="_blank">Resolution 1718</a>',
  DRC:
    'Democratic Republic of the Congo, <a href="https://www.undocs.org/S/RES/1533(2004)" target="_blank">Resolution 1533</a>',
  GB:
    'Guinea-Bissau, <a href="https://undocs.org/S/RES/2048(2012)" target="_blank">Resolution 2048</a>',
  //   "Iran",
  IRAQ:
    '<a href="https://www.undocs.org/S/RES/1518(2003)" target="_blank">Resolution 1518</a>, <a href="https://www.undocs.org/S/RES/661(1990)" target="_blank">Resolution 661</a>, <a href="https://www.undocs.org/S/RES/1483(2003)" target="_blank">Resolution 1483</a>',
  LIBYA:
    '<a href="https://www.undocs.org/S/RES/1970%20(2011)" target="_blank">Resolution 1970</a>',
  MALI:
    '<a href="https://undocs.org/S/RES/2374(2017)" target="_blank">Resolution 2374</a>',
  SOMALIA:
    '<a href="https://www.un.org/ga/search/view_doc.asp?symbol=S/RES/751(1992)" target="_blank">Resolution 751</a>',
  SOUTHSUDAN:
    '<a href="https://www.undocs.org/S/RES/2206(2015)" target="_blank">Resolution 2206</a>',
  SUDAN:
    '<a href="https://www.undocs.org/S/RES/1591(2005)" target="_blank">Resolution 1591</a>',
  // "Taliban",
  YEMEN:
    '<a href="https://www.undocs.org/S/RES/2140%20(2014)" target="_blank">Resolution 2140</a>',
};

export const EUDescription = {
  AFG:
    'Afghanistan, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?qid=1558683901455&uri=CELEX:02011D0486-20190220" target="_blank">Council Decision 2011/486/CFSP</a>, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?qid=1575544490436&uri=CELEX:02011R0753-20190709" target="_blank">Council Regulation (EU) No 753/2011</a>',
  BDI:
    'Burundi, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A02015D1763-20201031" target="_blank">Council Decision (CFSP) 2015/1763</a>, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A02015R1755-20201031" target="_blank">Council Regulation (EU) 2015/1755</a>',
  BLR:
    'Belarus, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A02006R0765-20201002" target="_blank">Council Regulation (EC) No 765/2006</a>, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A02012D0642-20201002" target="_blank">Council Decision 2012/642/CFSP</a>, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=uriserv%3AOJ.LI.2020.370.01.0001.01.ENG&toc=OJ%3AL%3A2020%3A370I%3ATOC" target="_blank">Council Implementing Regulation (EU) 2020/1648</a>, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=uriserv%3AOJ.LI.2020.370.01.0009.01.ENG&toc=OJ%3AL%3A2020%3A370I%3ATOC" target="_blank">Council Implementing Decision (CFSP) 2020/1650</a>',
  CAF:
    'Central African Republic, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?qid=1602494256663&uri=CELEX:02014R0224-20200923" target="_blank">Council Regulation (EU) No 224/2014</a>, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A02013D0798-20200923" target="_blank">Council Decision 2013/798/CFSP</a>',
  CHEM:
    'Chemical Weapons, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A02018D1544-20201015" target="_blank">Council Decision (CFSP) 2018/1544</a>, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A02018R1542-20201015" target="_blank">Council Regulation (EU) 2018/1542</a>',
  //COD: '<a href="" target="_blank"></a>',
  EGY:
    'Egypt, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?qid=1591775606558&uri=CELEX:02011R0270-20200321" target="_blank">Council Regulation (EU) No 270/2011</a>, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?qid=1591775606558&uri=CELEX:02011D0172-20200321" target="_blank">Council Decision 2011/172/CFSP</a>',
  //EUAQ: '<a href="" target="_blank"></a>',
  GIN:
    'Guinea, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A02010D0638-20201027" target="_blank">Council Decision 2010/638/CFSP</a>, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?qid=1594910336362&uri=CELEX:02009R1284-20191026" target="_blank">Council Regulation (EU) No 1284/2009</a>',
  GNB:
    'Guinea-Bissau, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?qid=1558684566865&uri=CELEX:02012D0285-20180111" target="_blank">Council Decision 2012/285/CFSP</a>, <a href="https://eur-lex.europa.eu/eli/reg/2012/377/2019-07-09" target="_blank">Council Regulation (EU) No 377/2012</a>',
  IRN:
    'Iran, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?qid=1591775606558&uri=CELEX:02011R0359-20200408" target="_blank">Council Regulation (EU) No 359/2011</a>, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?qid=1591775606558&uri=CELEX:02011D0235-20200408" target="_blank">Council Decision 2011/235/CFSP</a>',
  IRQ:
    'Iraq, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX:02003E0495-20140723" target="_blank">Council Common Position 2003/495/CFSP</a>, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?qid=1573038642422&uri=CELEX:02003R1210-20190709" target="_blank">Council Regulation (EC) No 1210/2003</a>, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX:31992R3541" target="_blank">Council Regulation (EEC) No 3541/92</a>, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?qid=1591775606558&uri=CELEX:02003R1210-20200118" target="_blank">Council Regulation (EC) No 1210/2003</a>',
  LBY:
    'Libya, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A02016R0044-20201002" target="_blank">Council Regulation (EU) 2016/44</a>, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A02015D1333-20201015" target="_blank">Council Decision (CFSP) 2015/1333</a>, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=uriserv:OJ.L_.2020.341.01.0007.01.ENG&toc=OJ:L:2020:341:TOC" target="_blank">Council Implementing Regulation (EU) 2020/1481</a>',
  MLI:
    'Mali, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?qid=1591775606558&uri=CELEX:02017R1770-20200128" target="_blank">Council Regulation (EU) 2017/1770</a>, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?qid=1591775606558&uri=CELEX:02017D1775-20200128" target="_blank">Council Decision (CFSP) 2017/1775</a>',
  MMR:
    'Myanmar, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?qid=1592482109019&uri=CELEX:02013R0401-20200425" target="_blank">Council Regulation (EU) No 401/2013</a>, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?qid=1591775606558&uri=CELEX:02013D0184-20200425" target="_blank">Council Decision 2013/184/CFSP</a>',
  PRK:
    'North Korea, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?qid=1599036353110&uri=CELEX:02016D0849-20200801" target="_blank">Council Decision (CFSP) 2016/849</a>, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?qid=1599036353110&uri=CELEX:02017R1509-20200801" target="_blank">Council Regulation (EU) 2017/1509</a>',
  SDN:
    'Sudan, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?qid=1571996568026&uri=CELEX:02014D0450-20180328" target="_blank">Council Decision 2014/450/CFSP</a>, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?qid=1571996507126&uri=CELEX:02014R0747-20190709" target="_blank">Council Regulation (EU) No 747/2014</a>',
  SOM:
    'Somalia, <a href="https://eur-lex.europa.eu/eli/dec/2010/231/2020-02-08" target="_blank">Council Decision 2010/231/CFSP</a>, <a href="https://eur-lex.europa.eu/eli/reg/2010/356/2018-12-12" target="_blank">Council Regulation (EU) No 356/2010</a>, <a href="https://eur-lex.europa.eu/eli/reg/2003/147/2020-02-08" target="_blank">Council Regulation (EC) No 147/2003</a>',
  SSD:
    'South Sudan, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?qid=1575553515994&uri=CELEX:02015D0740-20190718" target="_blank">Council Decision (CFSP) 2015/740</a>, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?qid=1571996624423&uri=CELEX:02015R0735-20190718" target="_blank">Council Regulation (EU) 2015/735</a>',
  SYR:
    'Syria, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?qid=1575554104408&uri=CELEX:02006R0305-20190709" target="_blank">Council Regulation (EC) No 305/2006</a>, <a href="https://eur-lex.europa.eu/legal-content/EN/ALL/?uri=CELEX:32005E0888" target="_blank">Council Common Position 2005/888/CFSP</a>',
  //TAQA: '<a href="" target="_blank"></a>',
  TERR:
    'Terrorism, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?qid=1599036353110&uri=CELEX:02016R1686-20200730" target="_blank">Council Regulation (EU) 2016/1686</a>, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A02016D1693-20201021" target="_blank">Council Decision (CFSP) 2016/1693</a>, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A02002R0881-20201013" target="_blank">Council Regulation (EC) No 881/2002</a>',
  TUN:
    'Tunisia, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?qid=1591775606558&uri=CELEX:02011R0101-20200129" target="_blank">Council Regulation (EU) No 101/2011</a>, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?qid=1592378153274&uri=CELEX:02011D0072-20200129" target="_blank">Council Decision 2011/72/CFSP</a>',
  TUR:
    'Turkey, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?qid=1591775606558&uri=CELEX:02019D1894-20200227" target="_blank">Council Decision (CFSP) 2019/1894</a>, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?qid=1591775606558&uri=CELEX:02019R1890-20200227" target="_blank">Council Regulation (EU) 2019/1890</a>, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=uriserv%3AOJ.LI.2020.372.01.0001.01.ENG&toc=OJ%3AL%3A2020%3A372I%3ATOC" target="_blank">Council Implementing Regulation (EU) 2020/1655</a>, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=uriserv%3AOJ.LI.2020.372.01.0016.01.ENG&toc=OJ%3AL%3A2020%3A372I%3ATOC" target="_blank">Council Decision (CFSP) 2020/1657</a>',
  UKR:
    'Ukraine, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?qid=1599036353110&uri=CELEX:02014D0386-20200620" target="_blank">Council Decision 2014/386/CFSP</a>, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX:02014R0692-20141220" target="_blank">Council Regulation (EU) No 692/2014</a>',
  //UNLI: '<a href="" target="_blank"></a>',
  VEN:
    'Venezuela, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?qid=1599036353110&uri=CELEX:02017R2063-20200629" target="_blank">Council Regulation (EU) 2017/2063</a>, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?qid=1599036353110&uri=CELEX:02017D2074-20200629" target="_blank">Council Decision (CFSP) 2017/2074</a>, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=uriserv%3AOJ.L_.2020.381.01.0008.01.ENG&toc=OJ%3AL%3A2020%3A381%3ATOC" target="_blank">Council Implementing Regulation (EU) 2020/1696</a>, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=uriserv%3AOJ.L_.2020.381.01.0024.01.ENG&toc=OJ%3AL%3A2020%3A381%3ATOC" target="_blank">Council Decision (CFSP) 2020/1700</a>',
  YEM:
    'Yemen, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?qid=1591775606558&uri=CELEX:02014R1352-20200404" target="_blank">Council Regulation (EU) No 1352/2014</a>, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?qid=1591775606558&uri=CELEX:02014D0932-20200404" target="_blank">Council Decision 2014/932/CFSP</a>',
  ZWE:
    'Zimbabwe, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?qid=1591775606558&uri=CELEX:02011D0101-20200218" target="_blank">Council Decision 2011/101/CFSP</a>, <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?qid=1591775606558&uri=CELEX:02004R0314-20200219" target="_blank">Council Regulation (EC) No 314/2004</a>',
};
export default {
  OFACDescription,
  UNDescription,
  EUDescription,
};
