import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  UncontrolledPopover as Popover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
import HTMLParse from "html-react-parser";
import classNames from "classnames";
import "./Checkbox.style.scss";

const Checkbox = ({
  id,
  className,
  label,
  labelPopover,
  onChange,
  value,
  required,
  uncheckedPrimary,
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const toggle = () => setPopoverOpen(!popoverOpen);

  const handleChange = (e) => (!onChange ? null : onChange(e.target.checked));
  return (
    <div className={classNames("checkbox", className)}>
      <input
        type="checkbox"
        id={id}
        onChange={handleChange}
        //defaultChecked={value}
        checked={value}
        required={required}
      />
      <label
        className={!uncheckedPrimary ? "border-default" : ""}
        style={{
          fontSize: label && label.length >= 30 && "13px",
          lineHeight: label && label.length >= 30 && "15px",
        }}
        htmlFor={id}
      >
        {label}{" "}
        {labelPopover && (
          <span id={`pop-${id}`} className="se-popover-trigger">
            <i className="fas fa-info-circle"></i>
          </span>
        )}
      </label>
      {labelPopover && (
        <Popover
          trigger="hover"
          placement="top"
          isOpen={popoverOpen}
          target={`pop-${id}`}
          toggle={toggle}
        >
          <PopoverHeader>{label}</PopoverHeader>
          <PopoverBody>{HTMLParse(labelPopover)}</PopoverBody>
        </Popover>
      )}
    </div>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.bool,
  required: PropTypes.bool,
  uncheckedPrimary: PropTypes.bool,
};

Checkbox.defaultProps = {
  value: false,
  className: "",
  label: "",
  labelPop: false,
  uncheckedPrimary: true,
};

export default Checkbox;
