export default class User {
  /**
   * Return an action to update the document info
   * @param {Object} data The document data extracted
   */
  static setPrograms(data) {
    return {
      type: "SET_PROGRAMS",
      data,
    };
  }
}
