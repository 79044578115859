import React from "react";
import { withRouter } from "react-router-dom";
import { Label, CustomInput } from "reactstrap";
import { Button, Checkbox, Input, Select2, Country } from "components/Form";
import { Programs, LeavePrograms } from "components/Modals";
import { getUrlParams } from "helpers/Utils";
import { connect } from "react-redux";
import MiscAction from "store/actions/misc";
import APIs from "services/APIs";
import CheckBoxGroup from "./CheckBoxGroup";
import DatePickerRange from "./DatePickerRange";
import SortBy from "./Sort";
import EventByDate from "./EventByDate";
import advSearchFields from "json/adv-search-field.json";
import "./Sidebar.style.scss";

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: {
        query: "",
        adv: {},
      },
      type: [],
      sanctioning_authority: [],
      dateRange: {},
      programs: {
        ALL_P: true,
        OFAC: [],
        UN: [],
        EU: [],
        UK: [],
        BIS: [],
        JAPAN: []
      },
      sort: {
        entity: "ASC",
      },
      showPrograms: false,
      showLeavePrograms: false,
      advSelected: [],
    };
  }

  componentDidMount() {
    const { defaultParams = {} } = this.props;
    const { filter = {}, sort = {}, search = {} } = defaultParams;
    const { type, sanctioning_authority, yearRange, programs } = filter;

    this.setState({
      type,
      sanctioning_authority,
      yearRange,
      programs,
      sort,
      search,
      advSelected: search.adv ? Object.keys(search.adv) : [],
    });

    if (this.props.misc && Object.keys(this.props.misc).length === 0) {
      this.getInfo();
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { defaultParams = {} } = nextProps;
    const { filter = {}, sort = {}, search = {} } = defaultParams;
    const { type, sanctioning_authority, yearRange, programs } = filter;

    this.setState({
      type,
      sanctioning_authority,
      yearRange,
      programs,
      sort,
      search,
      advSelected: search.adv ? Object.keys(search.adv) : [],
    });
  }

  getInfo = () => {
    APIs.getInfo().then(({ status, sanctions_programs = {} }) => {
      if (status === "success") {
        this.props.setPrograms(sanctions_programs);
      }
    });
  };

  generateCheckboxGroupData = () => {
    let checkBoxfilters = [
      {
        slug: "type",
        title: "Type",
        entities: ["individual", "organization", "aircraft", "vessel"],
      },
      {
        slug: "sanctioning_authority",
        title: "Sanctioning authority",
        entities: ["OFAC", "UN", "EU", "UK", "BIS", "JAPAN"],
      },
    ];

    checkBoxfilters = checkBoxfilters.map((item) => {
      let temp = { ...item };
      temp["defaultValues"] = this.state[item.slug];
      return temp;
    });

    return checkBoxfilters;
  };

  renderModals = () => {
    const {
      showPrograms,
      showLeavePrograms,
      programs = {},
      sanctioning_authority = [],
    } = this.state;
    if (sanctioning_authority.length === 0) return;
    return (
      <>
        <Programs
          all={programs.ALL_P}
          data={programs}
          onProgramSelect={(data, empty) =>
            this.setState({
              programs: data,
              showLeavePrograms: empty,
              showPrograms: false,
            })
          }
          sanctioning_authority={sanctioning_authority}
          show={showPrograms}
          onClose={(empty) =>
            this.setState({ showPrograms: false, showLeavePrograms: empty })
          }
        />
        <LeavePrograms
          show={showLeavePrograms}
          onContinue={() =>
            this.setState({ showPrograms: true, showLeavePrograms: false })
          }
          onClose={() =>
            this.setState({ showPrograms: false, showLeavePrograms: false })
          }
        />
      </>
    );
  };

  openProgramsModal = (e) => {
    e.preventDefault();
    this.setState({ showPrograms: true });
  };

  handleSelectAllPrograms = () => {
    let programs = { ...this.state.programs };
    programs.ALL_P = !programs.ALL_P;
    this.setState({
      programs,
    });
  };

  clearFilters = (e) => {
    e.preventDefault();
    const date = new Date();
    const currentYear = date.getFullYear();

    this.setState({
      type: ["individual", "organization", "aircraft", "vessel"],
      sanctioning_authority: ["OFAC", "EU", "UN", "UK", "BIS", "JAPAN"],
      yearRange: {
        startYear: 1994,
        endYear: currentYear,
      },
      dateRange: {},
      programs: {
        ALL_P: true,
        OFAC: [],
        UN: [],
        EU: [],
        UK: [],
        BIS: [],
        JAPAN: []
      },
      sort: {},
      search: {
        query: "",
        adv: {},
      },
      advSelected: [],
    });
  };

  renderAdvanceSearch = () => {
    const handleChange = (name, value) => {
      const search = { ...this.state.search };
      search.adv[name] = value;
      this.setState({
        search,
      });
      //this.props.onChange("search", search);
    };
    const handleDelete = (name) => {
      let search = { ...this.state.search };
      delete search.adv[name];
      let selected = [...this.state.advSelected];
      const advSelected = selected.filter((item) => {
        return item !== name;
      });
      this.setState({
        search,
        advSelected,
      });
    };

    const { search = {} } = this.state;
    const { adv = {} } = search;
    let advSelected = [...this.state.advSelected];
    let options = Object.values(advSearchFields).map(({ name, label }) => {
      if (!advSelected.includes(name)) {
        return {
          value: name,
          label: label,
        };
      }
    });
    options = options.filter((e) => e);
    return (
      <div className="filter-group mt-3">
        {options.length > 0 && (
          <label className="filter-group-title w-100 mb-2">
            Additional Filters
            <Select2
              tabIndex="-1"
              onChange={({ value }) => {
                advSelected = [...advSelected, value];
                this.setState({
                  advSelected,
                });
              }}
              value={null}
              options={options}
              placeholder="Select Field"
            />
          </label>
        )}
        <div className="mt-2">
          {advSelected.map((item) => {
            let { name, label, placeholder, type = null } = advSearchFields[
              item
            ];
            return (
              <div key={name} className="mt-1">
                <Label for={`#sf-${item}`}>
                  {label}{" "}
                  <i
                    onClick={() => handleDelete(item)}
                    className="fas fa-times text-primary"
                  ></i>
                </Label>
                {name !== "event_by_date" &&
                  name !== "active_sanctions" &&
                  !type && (
                    <Input
                      aria-label={placeholder}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          this.onSubmitSearch(search.query);
                        }
                      }}
                      id={`sf-${item}`}
                      value={adv[item] || ""}
                      onChange={(e) => handleChange(item, e.target.value)}
                      placeholder={placeholder}
                    />
                  )}
                {type === "country" && (
                  <Country
                    onChange={(val) => handleChange(item, val)}
                    placeholder={placeholder}
                    value={adv[item] || null}
                  />
                )}
                {name === "event_by_date" && (
                  <EventByDate onChange={(val) => handleChange(item, val)} />
                )}
                {name === "active_sanctions" && (
                  <Select2
                    onChange={(val) => {
                      handleChange(item, val.value);
                    }}
                    value={{
                      value: adv[item] || "Any",
                      label: adv[item] || "Any",
                    }}
                    options={[
                      { value: "Any", label: "Any" },
                      { value: "Active", label: "Active" },
                      { value: "InActive", label: "InActive" },
                    ]}
                    placeholder="Select..."
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  handleSearchChange = (e) => {
    const search = { ...this.state.search };
    search.query = e.target.value;

    //this.props.onChange("search", search);
    this.setState({
      search,
    });
  };

  getFilterParams = () => {
    const {
      type,
      sanctioning_authority,
      programs,
      sort,
      search,
      dateRange,
    } = this.state;
    const { hasSortBy, hasDateRange, hasSearch } = this.props;

    let params = {};
    params["filter"] = {
      type,
      sanctioning_authority,
      programs,
    };

    if (hasDateRange) {
      params["filter"]["dateRange"] = dateRange;
    }

    if (hasSortBy) {
      params["sortBy"] = sort;
    }

    if (hasSearch) {
      params["search"] = search;
    }

    return params;
  };

  onSubmitFilters = () => {
    let params = this.getFilterParams();
    this.props.onApplyFilter(params);
  };

  onSubmitSearch = (search) => {
    let params = this.getFilterParams();
    this.props.onSearch(search, params);
    this.props.history.push(window.location.pathname.split("?")[0]);
  };

  render() {
    const { hasSearch, hasSortBy, hasDateRange } = this.props;
    const { dateRange, programs = {}, sort = {}, search } = this.state;
    const checkBoxfilters = this.generateCheckboxGroupData();
    return (
      <aside className="sidebar">
        {hasSearch && <h2 className="title">Search</h2>}
        {hasSearch && (
          <div className="search-box">
            <Input
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  this.onSubmitSearch(search.query);
                }
              }}
              onChange={this.handleSearchChange}
              value={search.query}
              placeholder="Search"
              aria-label="Search"
            />

            {this.renderAdvanceSearch()}

            {false && (
              <div className="d-flex mt-3">
                <Button
                  onClick={() => this.onSubmitSearch(search.query)}
                  className="ml-auto"
                  color="primary"
                >
                  Search
                </Button>
              </div>
            )}
          </div>
        )}
        <h2 className="title">Filter</h2>
        {checkBoxfilters.map((checkBoxfilter, i) => {
          return (
            <CheckBoxGroup
              key={i}
              fieldData={checkBoxfilter}
              onChange={(val) => this.setState({ [checkBoxfilter.slug]: val })}
            />
          );
        })}

        <div className="filter-group">
          <h4 className="filter-group-title">Programs</h4>
          <div className="halfs">
            <div className="half mb-0">
              <a onClick={(e) => this.openProgramsModal(e)} href="#">
                Select programs
              </a>
            </div>
            <div className="half mb-0">
              <Checkbox
                id="all_programs"
                value={programs.ALL_P}
                label="All Programs"
                onChange={this.handleSelectAllPrograms}
              />
            </div>
          </div>
        </div>
        {hasDateRange && (
          <DatePickerRange
            className="filter-group mt-4"
            title="Date range"
            value={dateRange}
            onChange={(val) => this.setState({ dateRange: val })}
          />
        )}
        {hasSortBy && (
          <SortBy
            className="filter-group mt-4"
            value={sort}
            onChange={(val) => this.setState({ sort: val })}
          />
        )}

        <div className="filter-actions">
          <a href="#" onClick={this.clearFilters}>
            Reset all filters
          </a>
          {!hasSearch && (
            <Button
              onClick={this.onSubmitFilters}
              className="ml-auto"
              color="primary"
            >
              Apply
            </Button>
          )}
          {hasSearch && (
            <Button
              onClick={() => this.onSubmitSearch(search.query)}
              className="ml-auto"
              color="primary"
            >
              Search
            </Button>
          )}
        </div>
        {this.renderModals()}
      </aside>
    );
  }
}

export const SIDEBAR = withRouter(Sidebar);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Sidebar)
);

function mapStateToProps({ misc }) {
  return { misc };
}

function mapDispatchToProps(dispatch) {
  return {
    setPrograms: (data) => dispatch(MiscAction.setPrograms(data)),
  };
}
