import React, { Component } from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { Button, Modal, ModalHeader, Row, Col, Collapse } from "reactstrap";
import { Checkbox } from "components/Form";
import programData from "./data/programsData";
import "./Programs.style.scss";

const { OFACDescription, UNDescription, EUDescription } = programData;
export class Programs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      OFAC: [],
      UN: [],
      EU: [],
      OFAC_ALL: false,
      UN_ALL: false,
      EU_ALL: false,
      ALL: false,
      collapseData: {},
      sanctioning_authority: [],
    };
  }

  componentDidMount() {
    const { all = false, sanctioning_authority, misc = {} } = this.props;
    if (!all) {
      const { OFAC, UN, EU } = misc;
      this.setState({
        OFAC,
        UN,
        EU,
      });
    } else {
      this.handleSelectAll(true);
    }
    this.setState({
      sanctioning_authority,
    });
  }

  componentWillReceiveProps(nextProps) {
    const { all = false, misc = {} } = nextProps;
    if(this.state.EU.length === 0){
      if (!all) {
        const { OFAC, UN, EU } = misc;
        this.setState({
          OFAC,
          UN,
          EU,
        });
      } else {
        this.handleSelectAll(true);
      }
    }
    
  }

  toggle = (e) => {
    const { OFAC = [], UN = [], EU = [] } = this.state;
    const empty = OFAC.length === 0 && UN.length === 0 && EU.length === 0;

    e.preventDefault();
    this.props.onClose(empty);
  };

  handleChange = async (item, category) => {
    const {
      OFAC = [],
      UN = [],
      EU = [],
    } = this.props.misc;

    const OFACprograms = { title: "OFAC", items: OFAC }
    const UNprograms = { title: "UN", items: UN }
    const EUprograms = { title: "EU", items: EU }

    const categoryAll = [...this.props.misc[category]];
    if (item) {
      let existingValues = [...this.state[category]];
      if (existingValues.includes(item)) {
        //remove
        existingValues = existingValues.filter((ele) => ele !== item);
      } else {
        //add
        existingValues.push(item);
      }
      await this.setState({
        [category]: existingValues,
        [category + "_ALL"]: categoryAll.length === existingValues.length,
      });
    } else {
      let oldValue = this.state[category + "_ALL"];
      let bulk = [];
      if (!oldValue) {
        //it's going to be select all
        bulk = categoryAll;
      }
      await this.setState({
        [category + "_ALL"]: !oldValue,
        [category]: bulk,
      });
    }

    const total =
      OFACprograms.items.length +
      UNprograms.items.length +
      EUprograms.items.length;
    const allSelected = [...this.state.OFAC, ...this.state.EU, ...this.state.UN]
      .length;

    if (total === allSelected) {
      this.setState({
        ALL: true,
      });
    } else {
      this.setState({
        ALL: false,
      });
    }
  };

  handleApply = () => {
    const {
      OFAC = [],
      UN = [],
      EU = [],
      ALL,
      sanctioning_authority = [],
    } = this.state;
    const empty = OFAC.length === 0 && UN.length === 0 && EU.length === 0;
    let programs = {
      ALL_P: ALL,
    };

    sanctioning_authority.forEach((sa) => {
      programs[sa] = this.state[sa];
    });

    this.props.onProgramSelect(programs, empty);
  };

  handleCollapse = (e, key) => {
    const clickedTag = e.target.tagName;
    if (clickedTag === "A") return;
    const collapseData = { ...this.state.collapseData };
    collapseData[key] = !collapseData[key];
    this.setState({
      collapseData,
    });
  };

  renderCheckboxes = (data = {}, description = null) => {
    const state = {...this.state}
    const { collapseData = {}, sanctioning_authority = [] } = state;
    if (!sanctioning_authority.includes(data.title)) return;

    return (
      <div className="group">
        {data && (
          <div
            onClick={(e) => this.handleCollapse(e, data.title.toLowerCase())}
            className={classnames(
              "group-title-collapsed d-flex align-items-center",
              { isOpen: collapseData[data.title.toLowerCase()] }
            )}
          >
            <div className="group-title">
              <h4>{data.title}</h4>
              <Checkbox
                id={`all_${data.title.toLowerCase()}`}
                uncheckedPrimary={false}
                className="text-bold"
                label={`Select all ${data.title} programs`}
                value={this.state[data.title + "_ALL"]}
                onChange={() => this.handleChange(null, data.title)}
              />
            </div>
            <span className="ml-auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 480 480"
                xmlSpace="preserve"
              >
                <g>
                  <g>
                    <g>
                      <path
                        d="M240,0C107.452,0,0,107.452,0,240s107.452,240,240,240c132.486-0.15,239.85-107.514,240-240C480,107.452,372.548,0,240,0
				z M240,464C116.288,464,16,363.712,16,240S116.288,16,240,16c123.653,0.141,223.859,100.347,224,224
				C464,363.712,363.712,464,240,464z"
                      />
                      <path
                        d="M370.112,170.576L240,300.688L109.888,170.576c-3.1-2.994-8.015-2.994-11.115,0c-3.178,3.069-3.266,8.134-0.197,11.312
				l135.768,135.768c1.5,1.5,3.534,2.344,5.656,2.344c2.122,0,4.156-0.844,5.656-2.344l135.768-135.768
				c0.067-0.064,0.132-0.13,0.196-0.196c3.069-3.178,2.982-8.242-0.196-11.312C378.246,167.31,373.182,167.398,370.112,170.576z"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </span>
          </div>
        )}

        <Collapse isOpen={collapseData[data.title.toLowerCase()]}>
          {data.items.length > 0 && (
            <Row className="bulk-actions mt-4">
              {data.items.map((item) => {
                return (
                  <Col key={item} sm={6} md={4}>
                    <Checkbox
                      id={`${data.title.toLowerCase()}-${item}`}
                      uncheckedPrimary={false}
                      label={item.toUpperCase()}
                      labelPopover={description ? description[item] : null}
                      value={this.state[data.title].includes(item)}
                      onChange={() => this.handleChange(item, data.title)}
                    />
                  </Col>
                );
              })}
            </Row>
          )}
        </Collapse>
      </div>
    );
  };

  handleSelectAll = (forceSelectAll = false) => {
    const {
      OFAC = [],
      UN = [],
      EU = [],
    } = this.props.misc;

    const OFACprograms = { title: "OFAC", items: OFAC }
    const UNprograms = { title: "UN", items: UN }
    const EUprograms = { title: "EU", items: EU }

    const { ALL } = this.state;
    if (!ALL || forceSelectAll) {
      //Select all
      this.setState({
        OFAC: OFACprograms.items,
        UN: UNprograms.items,
        EU: EUprograms.items,
        OFAC_ALL: true,
        EU_ALL: true,
        UN_ALL: true,
        ALL: true,
      });
    } else {
      this.setState({
        OFAC: [],
        UN: [],
        EU: [],
        OFAC_ALL: false,
        EU_ALL: false,
        UN_ALL: false,
        ALL: false,
      });
    }
  };

  clearSelectAll = (e) => {
    e.preventDefault();
    this.setState({
      OFAC: [],
      UN: [],
      EU: [],
      OFAC_ALL: false,
      EU_ALL: false,
      UN_ALL: false,
      ALL: false,
    });
  };

  render() {
    const { show, misc = {} } = this.props;
    const { ALL } = this.state
    const {
      OFAC = [],
      UN = [],
      EU = [],
    } = misc;

    const OFACprograms = { title: "OFAC", items: OFAC }
    const UNprograms = { title: "UN", items: UN }
    const EUprograms = { title: "EU", items: EU }

    return (
      <Modal size="lg" isOpen={show || false} className="programs-modal">
        <ModalHeader toggle={this.toggle}>Programs</ModalHeader>
        <p>
          Programs are specific to each sanctioning authority. Select the
          programs that you would like to filter by.
        </p>
        <Row className="bulk-actions mb-2">
          <Col md={4}>
            <Checkbox
              id="select_all_popup"
              uncheckedPrimary={false}
              onChange={this.handleSelectAll}
              className="text-bold"
              label="Select all programs"
              value={ALL}
            />
          </Col>
          <Col className="text-right" md={8}>
            <a onClick={this.clearSelectAll} href="#">
              Clear all
            </a>
          </Col>
        </Row>
        {this.renderCheckboxes(OFACprograms, OFACDescription)}
        {this.renderCheckboxes(UNprograms, UNDescription)}
        {this.renderCheckboxes(EUprograms, EUDescription)}

        <div className="mt-3 text-center">
          <Button onClick={this.handleApply} color="primary">
            Apply
          </Button>
        </div>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, null)(Programs);

function mapStateToProps({ misc }) {
  return { misc };
}
