import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePickerRange.style.scss";

class EventByDate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
    };
  }

  formatDate = (date) => {
    let d = date,
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;

    if (day.length < 2) day = "0" + day;

    //return [day, month, year].join("-");
    return [year, month, day].join("-");
  };

  handleChange = (field, value) => {
    const state = { ...this.state };
    this.setState({
      [field]: value,
    });
    state[field] = value;
    const result = {
      startDate:
        state.startDate && typeof state.startDate === "object"
          ? this.formatDate(state.startDate)
          : state.startDate,
      endDate:
        state.endDate && typeof state.endDate === "object"
          ? this.formatDate(state.endDate)
          : state.endDate,
    };

    this.props.onChange(result);
  };

  componentWillReceiveProps(nextProps) {
    if (Object.keys(nextProps.value).length === 0) {
      this.setState({
        startDate: null,
        endDate: null,
      });
    }
  }

  render() {
    const { title, className } = this.props;
    const { startDate, endDate } = this.state;

    return (
      <div className={classnames("date-picker", className)}>
        {title && <h4>{title}</h4>}
        <div className="d-flex">
          <div className="pr-1">
            <label id="start_date">
              Start Date
              <DatePicker
                ariaLabelledBy="start_date"
                placeholderText="MM-dd-yyyy"
                className="form-control"
                minDate={new Date("01-01-1994")}
                dateFormat="MM-dd-yyyy"
                peekNextMonth={true}
                showMonthDropdown={true}
                showYearDropdown={true}
                dropdownMode="select"
                selected={startDate}
                onChange={(date) => this.handleChange("startDate", date)}
              />
            </label>
          </div>
          <div className="pl-1">
            <label id="end_date">
              End Date
              <DatePicker
                ariaLabelledBy="end_date"
                placeholderText="MM-dd-yyyy"
                className="form-control"
                minDate={new Date("01-01-1994")}
                dateFormat="MM-dd-yyyy"
                selected={endDate}
                peekNextMonth={true}
                showMonthDropdown={true}
                showYearDropdown={true}
                dropdownMode="select"
                onChange={(date) => this.handleChange("endDate", date)}
              />
            </label>
          </div>
        </div>
      </div>
    );
  }
}

EventByDate.propTypes = {
  onChange: PropTypes.func,
};

EventByDate.defaultProps = {
  onChange: () => {},
};

export default EventByDate;
