import React from "react";
import PropTypes from "prop-types";
import "./E500.style.scss";
import classNames from "classnames";
class E500 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { message, isPage } = this.props;

    return (
      <div
        className={classNames({
          "item-500": !isPage,
          "item-500-page": isPage,
        })}
      >
        <div>{message}</div>
      </div>
    );
  }
}

E500.propTypes = {
  message: PropTypes.string.isRequired,
  isPage: PropTypes.bool,
};

E500.defaultProps = {
  message: "Something went wrong on our side",
  isPage: false,
};

export default E500;
