import React from "react";
import { Container } from "reactstrap";
import { InternetCut } from "components";
import { Header, Footer } from "components/Layout";
import classnames from "classnames";
import PropTypes from "prop-types";
import "./Page.style.scss";

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { className, fluid, showFooter = false } = this.props;
    return (
      <React.Fragment>
        <InternetCut />
        <Header />
        <Container fluid={fluid} className={classnames("page", className)}>
          {this.props.children}
        </Container>
        {showFooter && <Footer />}
      </React.Fragment>
    );
  }
}

Page.propTypes = {
  className: PropTypes.string,
  fluid: PropTypes.bool,
};

Page.defaultProps = {
  className: "",
  fluid: false,
  showFooter: false,
};

export default Page;
