import React from "react";
import PropTypes from "prop-types";
import { Spinner } from "reactstrap";
import "./Loading.style.scss";

class Loading extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { visible = true } = this.props;

    if (!visible) return false;

    return (
      <div className="loading-container">
        <div className="d-flex m-1">
          {/*<Spinner type="grow" color="primary" />
                    <Spinner type="grow" color="secondary" />
                    <Spinner type="grow" color="primary" />*/}
          <div className="loader">Loading...</div>
        </div>
      </div>
    );
  }
}

Loading.propTypes = {
  visible: PropTypes.bool.isRequired,
};

Loading.defaultProps = {
  visible: true,
};

export default Loading;
