import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Select2 } from "components/Form";
import "./Sort.style.scss";

class Sort extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options1: [],
      options2: [],
      field: null,
      sortBy: null,
    };
  }

  componentDidMount() {
    const { value } = this.props;
    const fields = Object.keys(value);
    if (fields.length > 0) {
      const field = fields[0];
      const sortBy = value[field];
      const options = this.generateOptions();
      const found = options.find((opt) => {
        return opt.value === field;
      });
      this.setState({
        field: found,
        sortBy: { value: sortBy, label: sortBy },
      });
    }
  }

  generateOptions = () => {
    const option1 = [
      {
        label: "SDN/Entity",
        value: "entity",
      },
      {
        label: "Type",
        value: "type",
      },
      {
        label: "Authority",
        value: "authority",
      },
      {
        label: "Program",
        value: "program",
      },
    ];

    this.setState({
      options1: option1,
      options2: [
        { value: "ASC", label: "ASC" },
        { value: "DESC", label: "DESC" },
      ],
      sortBy: { value: "ASC", label: "ASC" },
    });

    return option1;
  };

  componentWillReceiveProps(nextProps, nextContext) {
    const { value } = nextProps;
    const fields = Object.keys(value);
    if (fields.length > 0) {
      const field = fields[0];
      const sortBy = value[field];
      const options = this.generateOptions();
      const found = options.find((opt) => {
        return opt.value === field;
      });
      this.setState({
        field: found,
        sortBy: { value: sortBy, label: sortBy },
      });
    } else {
      this.setState({
        field: null,
        sortBy: null,
      });
    }
  }

  handleChange = (type, obj) => {
    const { field, sortBy } = this.state;
    let result = {};
    if (type === "field") {
      result[obj.value] = sortBy.value;
    } else {
      result[field.value] = obj.value;
    }
    this.setState({
      [type]: obj,
    });
    this.props.onChange(result);
  };

  render() {
    const { options1 = [], options2 = [], field, sortBy } = this.state;
    const { className } = this.props;

    return (
      <div className={classnames("se-sort", className)}>
        <h4>Sort</h4>
        <div className="halfs">
          <div className="half pr-1">
            <Select2
              onChange={(val) => this.handleChange("field", val)}
              value={field}
              placeholder="Field"
              options={options1}
            />
          </div>
          <div className="half pl-1">
            <Select2
              onChange={(val) => this.handleChange("sortBy", val)}
              value={sortBy}
              placeholder="Sort by"
              options={options2}
            />
          </div>
        </div>
      </div>
    );
  }
}

Sort.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
};

Sort.defaultProps = {
  className: "",
  onChange: () => {},
};

export default Sort;
