import loadable from "react-loadable";
import { Loading } from "components";

const options = {
  loading: Loading,
  delay: 0,
  timeout: 5000,
};

export { options };

export default function oiLoadable() {
  return loadable({
    ...options,
  });
}
