import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ReactSelect from "react-select";
import classes from "./Select2.style.scss";

export default class Select2 extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  /**
   * Return the component's initial state
   * @return {Object}
   */
  getInitialState() {
    return {};
  }

  render() {
    const { color, outline = true, controlStyles, ...restProps } = this.props;

    const themeColor =
      color === "primary" ? classes.primary : classes.secondary;

    const carrotStyle = !outline
      ? { color: themeColor }
      : { color: themeColor };
    const IndicatorsContainer = ({ innerRef, innerProps }) => (
      <span {...innerProps}>
        <i style={carrotStyle} className="fas fa-caret-down"></i>
      </span>
    );

    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? "#fff" : themeColor,
        //borderBottom: state.isSelected ? 'none !important': 'initial',
        backgroundColor: state.isSelected ? classes.selected : "transparent",
        cursor: !state.isSelected ? "pointer" : "initial",
        textAlign: "left",
        ": hover": {
          color: !state.isSelected ? themeColor : "white",
          transition: "all ease 0.5s",
        },
        ": focus": {
          outline: "none",
        },
      }),
      control: (provided, state) => ({
        ...provided,
        width: "100%",
        //minWidth: '150px',
        fontSize: "14px",
        minHeight: "36px",
        outline: "none",
        boxShadow: "none",
        border: state.isFocused
          ? "2px solid " + themeColor
          : "1px solid " + themeColor,
        borderRadius: "2px",
        padding: "0 0.75rem",
        color: "#1F2327",
        "> div": {
          overflow: state.isFocused ? "inherit" : "hidden",
          padding: 0,
        },
        ": hover": {
          borderColor: !state.isSelected ? themeColor : "white",
        },
        ...controlStyles,
      }),
      placeholder: (provided, state) => {
        return {
          ...provided,
          color: classes.secondary,
        };
      },
      indicatorSeparator: (provided, state) => ({
        ...provided,
        display: "none",
      }),
      menu: (provided, state) => {
        return {
          ...provided,
          padding: "0",
          margin: "0",
          borderRadius: "0",
          boxShadow:
            "0 10px 20px rgba(0, 0, 0, 0), 0 6px 6px rgba(0, 0, 0, 0.11)",
          zIndex: 4,
        };
      },
      menuList: (provided, state) => {
        return {
          ...provided,
          padding: "0",
          div: {
            //borderBottom: '1px solid #E8E8EC',
            fontSize: "14px",
            //color: classes.primary,
          },
          "div:last-child": {
            borderBottom: "none",
          },
        };
      },
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";

        return {
          ...provided,
          color: themeColor,
          fontSize: "14px",
          lineHeight: "28px",
          opacity,
          transition,
        };
      },
    };

    if (!outline) {
      customStyles.control = (provided, state) => ({
        ...provided,
        width: "100%",
        //minWidth: '150px',
        border: "none",
        outline: "none",
        boxShadow: "none",
        ":focus": {
          border: "none",
        },
        ...controlStyles,
      });

      customStyles.singleValue = (provided, state) => {
        return {
          ...provided,
          color: themeColor,
          borderBottom: "2px solid " + themeColor,
        };
      };

      customStyles.placeholder = (provided, state) => {
        return {
          ...provided,
          color: themeColor,
          borderBottom: "2px solid " + themeColor,
        };
      };

      customStyles.indicatorsContainer = (provided, state) => {
        return {
          ...provided,
          color: themeColor,
        };
      };
    }

    return (
      <ReactSelect
        components={{ IndicatorsContainer }}
        styles={customStyles}
        isClearable={false}
        isSearchable={true}
        {...restProps}
      />
    );
  }
}
