import React, { Component } from "react";
import { Row, Col, Button } from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import ReCAPTCHA from "react-google-recaptcha";
import APIs from "services/APIs";
import { success, error } from "helpers/Tostify";
import { Input, Select2, RichText } from "components/Form";
import "./Feedback.style.scss";

class Feedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      type: null,
      feedback: null,
      isLoading: false,
      reCaptchaToken: "",
    };
    this.validator = new SimpleReactValidator({
      validators: {
        feedback: {
          message: "The :attribute is required.",
          rule: (val, params, validator) => {
            return val !== "<p><br></p>";
          },
          required: true, // optional
        },
      },
    });
    this.recaptcha = React.createRef();
  }

  componentDidMount() {
    const { defaultType = "" } = this.props;
    if (defaultType) {
      this.setState({
        type: { label: defaultType, value: defaultType },
      });
    }
  }

  componentWillUnmount() {
    this.setState({
      name: "",
      email: "",
      type: null,
      feedback: {},
    });
  }

  verifyCallback = (reCaptchaToken) => {
    this.setState({
      reCaptchaToken,
    });
  };

  handleChange = (e, field) => {
    const value = e.target.value;
    this.setState({
      [field]: value,
    });
  };

  getFeedbackTypes = () => {
    return [
      {
        value: "bug",
        label: "Bug Report",
      },
      {
        value: "feature",
        label: "Feature Request",
      },
      {
        value: "contact",
        label: "Get in Contact with the Team",
      },
      {
        value: "other",
        label: "Other",
      },
    ];
  };

  send = (params) => {
    const { onClose = null } = this.props;
    this.setState({
      isLoading: true,
    });
    APIs.sendFeedback(params)
      .then(({ status, message }) => {
        if (status === "success") {
          success("Sent successfully");
          if (onClose) onClose();
        } else {
          error(message);
        }
        this.setState({
          isLoading: false,
        });
      })
      .catch((e) => {
        error(e);
        this.setState({
          isLoading: false,
        });
      });
  };

  handleSubmit = () => {
    const { name, email, feedback, type, reCaptchaToken } = this.state;
    const input = {
      reCaptchaToken,
      name,
      email,
      type: type ? type.label : null,
      feedback,
    };

    if (this.validator.allValid()) {
      if (!reCaptchaToken) {
        error("Please check the Captcha");
        return;
      }
      this.send(input);
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleSelect2Change = (val) => {
    this.setState({
      type: val,
    });
  };

  render() {
    const { name, email, type = "", feedback, isLoading = false } = this.state;
    const options = this.getFeedbackTypes();
    const { REACT_APP_RECAPTCHA_KEY_V2 } = process.env;
    return (
      <div className="feedback-inner">
        <Row>
          <Col sm={6}>
            <Input
              aria-label="Name (Optional)"
              id="f_name"
              value={name}
              placeholder="Name (Optional)"
              onChange={(e) => this.handleChange(e, "name")}
            />
          </Col>
          <Col sm={6}>
            <Input
              aria-label="Email (Optional)"
              type="email"
              id="f_email"
              value={email}
              placeholder="Email (Optional)"
              onChange={(e) => this.handleChange(e, "email")}
            />
            {this.validator.message("email", email, "email")}
          </Col>
        </Row>
        <label className="w-100">
          <Select2
            className="mt-3"
            placeholder="Select Feedback Type"
            options={options}
            value={type}
            onChange={this.handleSelect2Change}
          />
        </label>

        {this.validator.message("type", type, "required")}
        <RichText onChange={(feedback) => this.setState({ feedback })} />
        <div className="d-flex mt-2">
          {this.validator.message("feedback", feedback, "feedback")}
          <small className="ml-auto">* To format double click on text.</small>
        </div>
        <div className="mt-3 d-flex align-items-center justify-content-between">
          <ReCAPTCHA
            ref={this.recaptcha}
            sitekey={REACT_APP_RECAPTCHA_KEY_V2}
            onChange={this.verifyCallback}
          />
          <Button
            disabled={isLoading}
            onClick={this.handleSubmit}
            color="primary"
          >
            Submit
          </Button>
        </div>
      </div>
    );
  }
}

export default Feedback;
