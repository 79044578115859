import React from "react";
import { Link } from "react-router-dom";
import { Table as ReactTable } from "reactstrap";
import classnames from "classnames";
import PropTypes from "prop-types";
import "./Table.style.scss";

class Table extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sort: {},
    };
  }

  sortMapping = () => {
    return {
      entity_name_display: "entity_name",
      entity: "type",
      sanction_authority: "authority",
      program_display_value: "programs",
      //date_added: "date_added",
      latest_update: "latest_update",
    };
  };

  handleSortClick = (e, field) => {
    e.preventDefault();
    const mapping = this.sortMapping();
    const eff_field = mapping[field];
    const { sortParams: sort } = this.props;

    const arr = Object.values(sort);
    let val = arr.length > 0 ? arr[0] : false;
    if (!val) {
      val = "asc";
    } else {
      val = val === "asc" ? "desc" : "asc";
    }

    this.props.onSort({
      [eff_field]: val,
    });
  };

  render() {
    const { className, tableData, apiCalled = false } = this.props;
    const { headers, data } = tableData;

    return (
      <div
        className={classnames({
          "se-table-container": true,
          "min-height": data.length < 1 && !apiCalled,
        })}
      >
        {data.length === 0 && apiCalled && (
          <div className="notfound-tips">
            <h2>SEARCH INFORMATION</h2>
            <h4>TIPS</h4>
            <ul>
              <li>
                Only a few entities have original-language names. Many searches
                in Arabic, Chinese, etc will not return results until
                sanctioning authorities publishes more original language names.
              </li>
              <li>
                If your searches are returning 0 results, try removing some
                words in the text boxes or remove some filters entirely.
              </li>
              <li>
                You may be using advanced search operators such as quotation
                marks; try removing these.
              </li>
              <li>
                There is no boolean search (e.g. "Russia OR Ukraine"). We are
                working on adding it.
              </li>
            </ul>
          </div>
        )}
        {data.length === 0 && !apiCalled && (
          <div className="notfound">Enter a search term to view results</div>
        )}
        {data.length > 0 && (
          <ReactTable className={classnames("se-table", className)}>
            <thead>
              <tr>
                {headers.map((header) => {
                  const { title, field, sort } = header;
                  return (
                    <th
                      scope="col"
                      onClick={(e) => this.handleSortClick(e, field)}
                      key={field}
                    >
                      {title}{" "}
                      {"sort" in header && (
                        <span
                          className={classnames("sort", {
                            asc: sort === "asc",
                            desc: sort === "desc",
                          })}
                        >
                          <i className="fas fa-sort-up"></i>
                          <i className="fas fa-sort-down"></i>
                        </span>
                      )}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((item, i) => {
                return (
                  <tr key={i} className={item.inactive ? "inactive" : ""}>
                    {headers.map((col, j) => {
                      if (j === 0) {
                        return (
                          <td scope="row" key={j}>
                            <Link key={i} to={`/entity?id=${item.id}`}>
                              {item[col.field]}
                            </Link>
                          </td>
                        );
                      }
                      if (typeof item[col.field] === "boolean") {
                        return (
                          <td scope={j === 0 ? "row" : ""} key={j}>
                            {item[col.field] ? "Yes" : "No"}
                          </td>
                        );
                      } else {
                        return (
                          <td scope={j === 0 ? "row" : ""} key={j}>
                            {item[col.field]}
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </tbody>
          </ReactTable>
        )}
      </div>
    );
  }
}

Table.propTypes = {
  className: PropTypes.string,
};

Table.defaultProps = {
  className: "",
  apiCalled: false,
};

export default Table;
