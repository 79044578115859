import React from "react";
import PropTypes from "prop-types";
import { Button, Checkbox } from "components/Form";
import classnames from "classnames";
import "./CheckBoxGroup.style.scss";

class CheckBoxGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
    };
  }

  componentDidMount() {
    const { fieldData = {} } = this.props;
    const { defaultValues = [] } = fieldData;
    this.setState({
      selected: defaultValues,
    });
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { fieldData = {} } = nextProps;
    const { defaultValues = [] } = fieldData;
    this.setState({
      selected: defaultValues,
    });
  }

  handleChange = (item) => {
    let existingValues = [...this.state.selected];
    if (existingValues.includes(item)) {
      //remove
      existingValues = existingValues.filter((ele) => ele !== item);
    } else {
      //add
      existingValues.push(item);
    }

    this.setState({
      selected: existingValues,
    });

    this.props.onChange(existingValues);
  };

  render() {
    const { className, fieldData = {} } = this.props;
    const { title, entities = [] } = fieldData;

    const { selected } = this.state;

    return (
      <div className={classnames("checkbox-group-sidebar", className)}>
        <h3 className="checkbox-group-sidebar-title">{title}</h3>
        <div className="halfs">
          {entities.map((entity) => {
            return (
              <div key={entity} className="half">
                <Checkbox
                  id={entity}
                  value={selected.includes(entity)}
                  label={entity}
                  onChange={() => this.handleChange(entity)}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

CheckBoxGroup.propTypes = {
  fieldData: PropTypes.object.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func,
};

CheckBoxGroup.defaultProps = {
  className: "",
  onChange: () => {},
};

export default CheckBoxGroup;
