import React, { Component } from "react";
import classnames from "classnames";
import { Input } from "reactstrap";
import "./Input.style.scss";

export default class SEInput extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { className, ...restProps } = this.props;
    return (
      <Input className={classnames("se-input", className)} {...restProps} />
    );
  }
}
