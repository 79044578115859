import React, { Component } from "react";
import classnames from "classnames";
import { EditorState, RichUtils, AtomicBlockUtils } from "draft-js";
import Editor from "draft-js-plugins-editor";
import { stateToHTML } from "draft-js-export-html";
import { mediaBlockRenderer } from "./entities/mediaBlockRenderer";
import "draft-js/dist/Draft.css";
import "./RichText.style.scss";

//https://codesandbox.io/s/n0ozyqr9z4?from-embed=&file=/src/App.js

export default class RichText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
    };
  }

  onChange = (editorState) => {
    this.setState({
      editorState,
    });
    this.props.onChange(stateToHTML(editorState.getCurrentContent()));
  };

  handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(
      this.state.editorState,
      command
    );
    if (newState) {
      this.onChange(newState);
      return "handled";
    }
    return "not-handled";
  };

  onURLChange = (e) => this.setState({ urlValue: e.target.value });

  focus = () => this.refs.editor.focus();

  onAddImage = (url) => {
    const editorState = this.state.editorState;
    const urlValue = url;
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "image",
      "IMMUTABLE",
      { src: urlValue }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(
      editorState,
      { currentContent: contentStateWithEntity },
      "create-entity"
    );
    this.setState(
      {
        editorState: AtomicBlockUtils.insertAtomicBlock(
          newEditorState,
          entityKey,
          " "
        ),
      },
      () => {
        setTimeout(() => this.focus(), 0);
      }
    );
  };

  onUnderlineClick = () => {
    this.onChange(
      RichUtils.toggleInlineStyle(this.state.editorState, "UNDERLINE")
    );
  };

  onBoldClick = () => {
    this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, "BOLD"));
  };

  onItalicClick = () => {
    this.onChange(
      RichUtils.toggleInlineStyle(this.state.editorState, "ITALIC")
    );
  };

  handleFilePaste = (files) => {
    const allowed = ["image/png", "image/jpg"];
    let images = files.filter((file) => {
      return allowed.includes(file.type);
    });

    const blob = images[0];
    if (blob !== null) {
      let reader = new FileReader();
      reader.onload = (event) => {
        const data_url = event.target.result;
        //const data_url = 'https://raw.githubusercontent.com/facebook/draft-js/master/examples/draft-0-10-0/convertFromHTML/image.png';
        this.onAddImage(data_url);
      };
      reader.readAsDataURL(blob);
    }
  };

  render() {
    return (
      <div className="se-rich-text">
        <div className="menuButtons d-flex justify-content-center">
          <button onClick={this.onUnderlineClick}>U</button>
          <button onClick={this.onBoldClick}>
            <b>B</b>
          </button>
          <button onClick={this.onItalicClick}>
            <em>I</em>
          </button>
        </div>
        <Editor
          ariaLabel="Message"
          blockRendererFn={mediaBlockRenderer}
          editorState={this.state.editorState}
          handleKeyCommand={this.handleKeyCommand}
          onChange={this.onChange}
          placeholder={`Type your feedback, paste images`}
          plugins={this.plugins}
          handlePastedFiles={this.handleFilePaste}
          ref="editor"
        />
      </div>
    );
  }
}
